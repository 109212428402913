import { useState, useEffect, useMemo } from 'react'
import basepath from '#components/basepath'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import { loaderProp } from '#root/utils/image-utils'

export default function RatingReview({ isMobile, t }) {
  const reviewUrl = useMemo(() => {
    return isMobile?.any()
      ? 'https://www.google.com.my/search?q=DoctorOnCall&ludocid=15802621416795524703&lsig=AB86z5VVk_CTad0GOOm2X0B9jXQR#lkt=LocalPoiReviews&trex=m_t:lcl_akp,rc_f:nav,rc_ludocids:15802621416795524703,rc_q:DoctorOnCall,ru_q:DoctorOnCall'
      : 'https://www.google.com.my/search?q=DoctorOnCall#lrd=0x31cc49dad8f703b3:0xa24e4afb922b989a,1,,,,'
  }, [isMobile])

  return (
    <div id="review" className="slider-container section review ">
      <div className="wrap-1 centralized pad">
        <ul className="controls flex-box fullwidth ai-b ls-n">
          <li className="prev" aria-controls="s2" tabIndex={-1} data-controls="prev">
            {' '}
            <i>
              <img src={basepath('/images/prev.svg')} alt="prev" />
            </i>{' '}
          </li>
          <li className="next" aria-controls="s2" tabIndex={-1} data-controls="next">
            {' '}
            <i>
              <img src={basepath('/images/next.svg')} alt="next" />
            </i>{' '}
          </li>
          <li className="headline">
            <h2>{t('customerFeedback')}</h2>
            <span>{t('seeWhatOurCustomersHaveToSayAboutOurService')}</span>
          </li>
          <li className="more">
            <a className="button-more" href={reviewUrl} target="_blank">
              <div className="dots">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div className="wrap-2 centralized">
        <div className="customer-feedback flex-box jc-sb gap-1 col4 ta-c">
          <a href={reviewUrl} className="desc pad-1 bg-white br-1 f-g0-s1">
            {' '}
            <Image
              src={'/images/avatar.svg'}
              placeholder="blur"
              blurDataURL={'/images/avatar.svg'}
              alt={'avatar'}
              width={87}
              height={87}
              className="avatar centralized"
              loader={loaderProp}
            />
            <h2>{t('kingLeeWong')}</h2>
            <div className="stars flex-box jc-c gap-05 mt-1 mb-1">
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
            </div>
            <p>{t('great')}</p>
          </a>
          <a href={reviewUrl} className="desc pad-1 bg-white br-1 f-g0-s1">
            {' '}
            <Image
              src={'/images/avatar.svg'}
              placeholder="blur"
              blurDataURL={'/images/avatar.svg'}
              alt={'avatar'}
              width={87}
              height={87}
              className="avatar centralized"
              loader={loaderProp}
            />
            <h2>{t('tuanFarisTuanIdzawi')}</h2>
            <div className="stars flex-box jc-c gap-05 mt-1 mb-1">
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
            </div>
            <p>{t('great2')}</p>
          </a>
          <a href={reviewUrl} className="desc pad-1 bg-white br-1 f-g0-s1">
            {' '}
            <Image
              src={'/images/avatar.svg'}
              placeholder="blur"
              blurDataURL={'/images/avatar.svg'}
              alt={'avatar'}
              width={87}
              height={87}
              className="avatar centralized"
              loader={loaderProp}
            />
            <h2>{t('nuruljannahRamli')}</h2>
            <div className="stars flex-box jc-c gap-05 mt-1 mb-1">
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
            </div>
            <p>{t('great3')}</p>
          </a>
          <a href={reviewUrl} className="desc pad-1 bg-white br-1 f-g0-s1">
            {' '}
            <Image
              src={'/images/avatar.svg'}
              placeholder="blur"
              blurDataURL={'/images/avatar.svg'}
              alt={'avatar'}
              width={87}
              height={87}
              className="avatar centralized"
              loader={loaderProp}
            />
            <h2>{t('davidTeoh')}</h2>
            <div className="stars flex-box jc-c gap-05 mt-1 mb-1">
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
              <div className="on"></div>
            </div>
            <p>{t('great4')}</p>
          </a>
        </div>
      </div>
    </div>
  )
}

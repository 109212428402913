import React,{useRef } from 'react'
import basepath from '#components/basepath'
import Api from '#api/Market'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import { anonymousAuth } from '#root/src/utilities/authUtils'
import { localeMapper } from '#root/utils/url-utils'
import { loaderProp } from '#root/utils/image-utils'
import ProductSlider from '#components/ProductSlider'
import RatingReview from '#components/RatingReview'
import MediaCarousel from '#components/MediaCarousel'
import GoogleAd from '#components/GoogleAd'


export default function index({newProduct,locale,pharmacy,pesonalizedProducts,newPackageProduct, isMobile,translatedCategories,productsCarousels}) {

  const { t } = useTranslation('index')
  const indexRef = useRef(0)

  const toggleBackground = () => {
    indexRef.current += 1
    return indexRef.current % 2 !== 0 ? 'bg-blue-2' : ''
  }
  return (
    <>

      <div className="main-content slide-disable sr t-05-ease">
        <div className={"thumbs pt-1 pb-1 "+ toggleBackground()}>
          <div className="wrap-2 centralized">
            <div className="thumbs flex-box fw gap-1">
              <div className="f-g1-s0"><a id="b1" href={basepath(`${locale==='en'?'':`/${locale}`}/${pharmacy}`)} target="_self" className="t-05-ease"><small>{t('online')}</small>
                  <div>
                  {t('pharmacy')}
                  </div>
                </a></div>
              <div  className="f-g1-s0 "><a id="b3" href= {basepath(`${locale==='en'?'':`/${locale}`}/health-services`)} target="_self" className="t-05-ease" ><small>{t('checkup')}</small>
                  <div>{t('healthScreeningPackages')}
                  </div>
                </a></div>
              <div className="f-g1-s0"><a id="b8" href= {basepath(`${locale==='en'?'':`/${locale}`}/health-services`)} target="_self" className="t-05-ease" ><small>{t('online')}</small>
                  <div>{t('homeHealthScreening')}
                  </div>
                </a></div>
              <div className="f-g1-s0"><a id="b6" href= {basepath(`${locale==='en'?'':`/${locale}`}/health-services`)} target="_self" className="t-05-ease" ><small>COVID-19</small>
                  <div>{t('kidsVaccination')}
                  </div>
                </a></div>
            </div>
          </div>
        </div>

        <div className={'pt-1 pb-1 o-h '+ toggleBackground()}>
            <div className="pharmacy-main-banners-wrapper centralized">
              <div className="pharmacy-main-banners flex-box gap-1 banners-slider google-ad-main">
                <GoogleAd adUnitPath='/21826856561/B2c-mp-main-banner-1' size={[1000, 420]} divId='div-gpt-ad-1722397442672-0'/>
                <div className="flex-box f-g1-s1 fd-c gap-1 no-scroller">
                  <GoogleAd adUnitPath='/21826856561/b2c-mp-sub-banner-1' size={[420, 190]} divId='div-gpt-ad-1722395327856-0'/>
                  <GoogleAd adUnitPath='/21826856561/B2c-mp-sub-banner-2' size={[420, 190]} divId='div-gpt-ad-1722395479203-0'/>
                </div>
              </div>
            </div>
              {/*
              <div className="pharmacy-main-banners-wrapper centralized">
                <div className="pharmacy-main-banners flex-box fw gap-1 banners-slider">
                  <div>
                      <a
                          className="card br-05"
                          href={locale === 'id'
                          ? '/id/apotek?utm_source=MarketplaceID&utm_medium=banner_1&utm_campaign=welcome_message'
                          : locale === 'sg'
                          ? '/sg/pharmacy?utm_source=MarketplaceSG&utm_medium=banner_1&utm_campaign=welcome_message'
                          : '/pharmacy?utm_source=MarketplaceMY&utm_medium=banner_1&utm_campaign=welcome_message'}
                          style={{
                            background:
                              `url(${locale === 'id'
                              ? '/images/banners/home_welcome_banner.png'
                              : locale === 'sg'
                              ? '/images/banners/home_welcome_banner.png'
                              : '/images/banners/home_welcome_banner.png'}) no-repeat center center`,
                              width:' 100%',
                              height: '100%',
                              backgroundSize: "cover",
                              display: "inline-block"
                          }}
                        ></a>
                  </div>
                  <div className="flex-box f-g1-s1 fd-c gap-1 no-scroller">
                    <a
                      href={locale === 'id'
                      ? '/id/health-services'
                      : locale === 'sg'
                      ? '/id?utm_source=MarketplaceSG&utm_medium=banner_2&utm_campaign=redirect_id '
                      : '/id?utm_source=MarketplaceMY&utm_medium=banner_2&utm_campaign=redirect_id '}
                      style={{
                        background:`url(${locale === 'id'
                        ? '/images/online-parmacy/indonesia-redirect-small.jpg'
                        : locale === 'sg'
                        ? '/images/online-parmacy/indonesia-redirect-small.jpg'
                        : '/images/online-parmacy/indonesia-redirect-small.jpg'}) no-repeat center center`
                      }}
                      className="card br-05 fb-50 f-g1-s1"
                    ></a>
                    <a
                      href={locale === 'id'
                      ? '/id/apotek/obat-resep?utm_source=MarketplaceID&utm_medium=banner_3&utm_campaign=free_delivery '
                      : locale === 'sg'
                      ? '/sg/pharmacy/prescription-medicine?utm_source=MarketplaceSG&utm_medium=banner_3&utm_campaign=free_delivery'
                      : '/health-services/categories/general-vaccination?utm_source=MarketplaceMY&utm_medium=banner_3&utm_campaign=vaccination'}
                      style={{
                        background: `url(${locale === 'id'
                        ? '/images/online-parmacy/free-shipping-sg-indo.png'
                        : locale === 'sg'
                        ? '/images/online-parmacy/free-shipping-sg-indo.png'
                        : '/images/online-parmacy/general-vaccination-small.jpg'}) no-repeat center center`
                      }}
                      className="card br-05 fb-50 f-g1-s1"
                    ></a>
                  </div>
                </div>
              </div>
              */}
        </div>
        
        {
          !productsCarousels.length>0?
          <>
            <div className={"section "+ toggleBackground()}>
              <ProductSlider
                  title={t('onlinePharmacyIndex')}
                  description={t('onlinePharmacyDesc')}
                  tag={t('newIndex')}
                  is_service={false}
                  items={newProduct}
                  translatedCategories={translatedCategories}
                  pharmacy={pharmacy}
                  locale={locale}
                  location={'homeIndex'}
                />
              </div>
          </>
            :
            productsCarousels.map((x) => {
              return (
                <div className={"section "+ toggleBackground()}>
                  <ProductSlider
                    title={x.title}
                    description={x.description[locale]}
                    items={x.content.items}
                    translatedCategories={translatedCategories}
                    pharmacy={pharmacy}
                    locale={locale}
                    collection={x}
                    location={'homeIndex'}
                    />
                </div>
              )
          })
        }

        <div className={"section "+ toggleBackground()}>
            <ProductSlider
              is_service={true}
              title={t('topPackeges')}
              description={t('checkOutOurMostPopularItems')}
              items={newPackageProduct}
              locale={locale}
              location={'homeIndex'}
            >
            </ProductSlider>
        </div>

        <div className={"section "+ toggleBackground()}>
          <div className="wrap-2 centralized">
            <div className="banners flex-box col3 fullwidth no-scroller-1024 gap-1">
              <a
                id="banner-01"
                href={basepath(`${locale==='en'?'':`/${locale}`}/${pharmacy}/vitamin-suplemen`)}
                className="pos-rel f-g1-s1"
              >
                <div className="pos-abs flex-box fd-c fullheight fullwidth">
                  <div>
                    <div className="category"><span>{t('personalCare')}</span></div>
                    <h2 style={{ color: '#de71a3' }}>{t('pamperYourselfWithDailyEssentails')}</h2>
                  </div>
                  <div>
                    <div className="button-white" style={{ color: '#b87489' }}>
                      {t('shopNow')}
                    </div>
                  </div>
                </div>
              </a>
              <a
                id="banner-02"
                href={basepath(`${locale==='en'?'':`/${locale}`}/${pharmacy}/vitamin-suplemen`)}
                className="pos-rel f-g1-s1"
              >
                <div className="pos-abs flex-box fd-c fullheight fullwidth">
                  <div>
                    <div className="category"><span>{t('overTheCounter')}</span></div>
                    <h2 style={{ color: '#767676' }}>{t('fromStuffyNoseToMinorInfections')}</h2>
                  </div>
                  <div>
                    <div className="button-white" style={{ color: '#767676' }}>
                      {t('shopNow')}
                    </div>
                  </div>
                </div>
              </a>
              <a
                id="banner-03"
                href={basepath(`${locale==='en'?'':`/${locale}`}/${pharmacy}/vitamin-suplemen`)}
                className="pos-rel f-g1-s1"
              >
                <div className="pos-abs flex-box fd-c fullheight fullwidth">
                  <div>
                    <div className="category"><span>{t('overTheCounter')}</span></div>
                    <h2 style={{ color: '#fff' }}>{t('getTheReliefYouNeed')}</h2>
                  </div>
                  <div>
                    <div className="button-white" style={{ color: '#767676' }}>
                      {t('shopNow')}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        
        {
          pesonalizedProducts?.length>5&&
          <div className={"section "+ toggleBackground()}>
            <ProductSlider
                title={t('justForYou')}
                description={t('personalizedSelection')}
                tag={t('newIndex')}
                is_service={false}
                items={pesonalizedProducts}
                translatedCategories={translatedCategories}
                pharmacy={pharmacy}
                locale={locale}
              >
            </ProductSlider>
          </div>
        }
        
        <div className={"section "+ toggleBackground()}>
          <div className="wrap-1 centralized pad ta-c">
          <h2>{t('partnersTitle')}</h2>
            <p className="fs-1 centralized" style={{ maxWidth : '50em' }}>{t('partnersDesc')}</p>
            <div className="logos flex-box fw jc-c gap-1 centralized" style={{maxWidth : '53em'}}>
              <img src="images/partner-01.png" alt="" />
              <img src="images/partner-02.png" alt="" />
              <img src="images/partner-03.png" alt="" />
              <img src="images/partner-04.png" alt="" />
              <img src="images/partner-05.png" alt="" />
              <img src="images/partner-06.png" alt="" />
              <img src="images/partner-07.png" alt="" />
              <img src="images/partner-08.png" alt="" />
              <img src="images/partner-09.png" alt="" />
              <img src="images/partner-10.png" alt="" />
              <img src="images/partner-11.png" alt="" />
              <img src="images/partner-12.png" alt="" />
              <img src="images/partner-13.png" alt="" />
              <img src="images/partner-14.png" alt="" />
              <img src="images/partner-15.png" alt="" />
              <img src="images/partner-16.png" alt="" />
              <img src="images/partner-17.png" alt="" />
              <img src="images/partner-18.png" alt="" />
              <img src="images/partner-19.png" alt="" /> 
            </div>
          </div>
        </div>
        <div className={"section "+ toggleBackground()}>
            <MediaCarousel>
            </MediaCarousel>
        </div>

        <div className={toggleBackground()}>
          <RatingReview
            isMobile={isMobile}
            t={t}
          >
          </RatingReview>
        </div>

      </div>
    </>
  )
}

export const getServerSideProps = async (context) => {
  
  const pharmacySlugs={
    id:'apotek',
    en:'pharmacy',
    sg:'pharmacy'
  }
  const { req,res } = context;
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  )
  let {
    i18nLocale,
    locale
  }= localeMapper(context.locale)

  let userToken=await anonymousAuth(req,res)
  
  let newProduct=await Api.getNewProducts(userToken,String(locale).toUpperCase())
  let newPackageProduct=await Api.getNewPackages(userToken,locale)
  let productsCarousels=(await Api.getPackageCollections({token:userToken,locale,type:'products',carousel:true})) ||null;

  let pesonalizedProducts=await Api.getPersonalized(userToken,String(locale).toUpperCase())

  let pageHeader={
    id:{
      title:'Apotek Online Untuk Obat, Suplemen & Alat Kesehatan - DoctorOnCall ID',
      description:'DoctorOnCall adalah apotek online di Indonesia terlengkap dan terpercaya yang menjual obat generik, obat OTC, produk kesehatan, kecantikan, dan perawatan tubuh.'
    },
    en:{
      title:'Online Pharmacy For Medicines, Supplements & Healthcare Devices - DoctorOnCall',
      description:'DoctorOnCall is your most trusted and reliable online pharmacy, offering a wide range of generic medicines, over-the-counter drugs, health products, beauty items, and personal care treatments.'
    }
  }
  let currentFullUrl = `${context.req.headers.host}${context.req.url}`
  let productCategories = await Api.getProductCategories()

  let translatedCategories = {};

  productCategories.map((category)=>{
    translatedCategories[category.language.en.name]=category.language[locale]?.url_slug ||category.language['en'].url_slug
  })
    
  return {
    props: {
      pageHeader:{
        title:pageHeader[i18nLocale]?.title||pageHeader['en'].title,
        description:pageHeader[i18nLocale]?.description||pageHeader['en'].description
      },
      newProduct:newProduct.products,
      newPackageProduct: newPackageProduct.packages,
      currentFullUrl,
      locale:locale,
      pharmacy:pharmacySlugs[locale],
      productsCarousels,
      pesonalizedProducts,
      translatedCategories,
      ...(await serverSideTranslations(i18nLocale, [
        'auth',
        'header',
        'footer',
        'index',
        'common',
      ]))
    }
  }
}